import { Grid, TextField } from "@mui/material";
import { Container } from "@mui/system";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import AzaLogo from "./Elements/AzaLogo";
import { Link } from "react-router-dom";
import Constants from "../Utils/Constants";
import AzaButton from "./Elements/Button/AzaButton";
import { WhatsApp } from "@mui/icons-material";

function Footer() {
    

    return(
        <>
        <section className="footer_upper">
            <h1>
                Hemen Teklif Al
            </h1>

            <h2>
                Bizimle şimdi iletişime geçin, sizin için en iyi teklifi hazırlayalım.
            </h2>

            <a target={"_blank"} href="https://api.whatsapp.com/send?phone=5432851949">
                <AzaButton 
                buttonProps={{
                    style: {
                        borderColor: "white",
                        color: "white",
                        borderRadius: 35
                    }
                }}
                startAdornment={<WhatsApp />}>
                    Teklif Al
                </AzaButton>
            </a>
        </section>
        <footer id="aza_footer">
            
            <Grid container maxWidth="xl">
                <Grid item xl={3} xs={8}>
                    <h4>İletişim</h4>

                    <ul>
                        <li>
                         <PhoneIcon className="aza_footer_icon" fontSize="small" /> 0212 571 41 17
                        </li>

                        <li>
                        <FaxOutlinedIcon className="aza_footer_icon" fontSize="small" /> 0212 571 37 02
                        </li>

                        <a target={"_blank"} href={"https://www.instagram.com/" + Constants.about_us_constants.contact.instagram_kutu.replace("@", "")}>
                            <li>
                            <InstagramIcon className="aza_footer_icon" fontSize="small" /> {Constants.about_us_constants.contact.instagram_kutu}
                            </li>
                        </a>

                        <a target={"_blank"} href={"https://www.facebook.com/" + Constants.about_us_constants.contact.facebook.replace("@", "")}>
                            <li>
                            <FacebookIcon className="aza_footer_icon" fontSize="small" /> @azakutuyapistirma
                            </li>
                        </a>
                    </ul>
                </Grid>

                <Grid item xl={3} xs={4}>
                    <h4>Kurumsal</h4>

                    <ul>
                        <Link to="/hakkimizda">
                            <li>
                            Hakkımızda
                            </li>
                        </Link>

                        <Link to="/portfoy">
                            <li>
                            Portföy
                            </li>
                        </Link>

                        <Link to="/vergi">
                            <li>
                                Vergi Tescili
                            </li>
                        </Link>
                    </ul>
                </Grid>

                <Grid container spacing={2} item xl={6} xs={12}>
                    <Grid item xl={12} xs={12} container justifyContent={"end"} sx={{ justifyContent: { xs: "center", md: "end" } }}>
                        <AzaLogo variant="logo_2" w={350} h={120} />
                    </Grid>
                </Grid>

                <Grid item xl={6} xs={12}>
                    <h4>
                        Adres
                    </h4>

                    <span style={{ color: "rgba(255, 255, 255, 0.8)" }}>
                    Maltepe Mh. Davutpaşa cd. Emintaş Davutpaşa sitesi. No: 101/199 - 201. Zeytinburnu / İstanbul
                    </span>
                </Grid>

                <Grid item xl={12} xs={12}>
                    <hr style={{ marginTop: 15, marginBottom: 15 }} />
                </Grid>

                <Grid container item xs={12}>
                    <Grid xl={6}  style={{ color: "rgba(255, 255, 255, 0.8)" }}>
                    © Telif Hakkı 2002 - 2022 | Aza Dış Ticaret, Tüm Hakları Saklıdır.
                    </Grid>

                    <Grid xl={6} style={{ textAlign: "right", color: "rgba(255, 255, 255, 0.8)", cursor: "pointer" }}>
                    <a href="http://cabiltek.com" target={"_blank"}>
                    CAWEB
                    </a>
                    </Grid>
                </Grid>
            </Grid>
        </footer>
        </>
    )
}

export default Footer;