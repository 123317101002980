import { Box, Button, Container, Divider, Grid, SwipeableDrawer } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AzaLogo from "./Elements/AzaLogo";
import PermPhoneMsgTwoToneIcon from '@mui/icons-material/PermPhoneMsgTwoTone';
import PrintTwoToneIcon from '@mui/icons-material/PrintTwoTone';
import AzaButton from "./Elements/Button/AzaButton";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import PublicIcon from '@mui/icons-material/Public';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { FaxOutlined, PhonelinkLockOutlined } from "@mui/icons-material";
import Constants from "../Utils/Constants";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

function Header() {
    const history = useHistory();

    const [selectedLang, setSelectedLang] = useState("TR");
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    

    const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
    
    useEffect(() => {
        HeaderScroll();
    }, []);

    useEffect(() => {
        var new_lang = selectedLang.toLowerCase();

        switch (selectedLang) {
            case "JP":
                new_lang = "ja"
                break;
            case "US":
                new_lang = "en";
                break;
            default:
                break;
        }
        
        history.push(`#googtrans(tr|${'en'})`);
    }, [selectedLang]);

    useEffect(() => {
        
        
          
        

    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    function HeaderScroll() {
        window.onscroll = function() {myFunction()};
        var header = document.getElementById("aza_header");
        var main_content = document.getElementById("main_content");
        var sticky = header!.offsetTop + 150;        
        function myFunction() {
          if (window.pageYOffset > sticky) {
            header!.classList.add("sticky");
            main_content!.classList.add("main_content_header_top")
          } else if (window.pageYOffset < header!.offsetTop + 1) {
            header!.classList.remove("sticky");
            main_content!.classList.remove("main_content_header_top")
          }
        }
    }

    

    return(
        <>
        <header id="aza_header">
            <Box className="up_section" width="100%">
                <Container maxWidth={"xl"}>
                    <ul>
                        <li style={{
                            marginLeft: 0
                        }}>
                         <PhonelinkLockOutlined className="aza_footer_icon" fontSize="small" /> 0212 571 41 17
                        </li>


                        <li>
                        <FaxOutlined className="aza_footer_icon" fontSize="small" /> 0212 571 37 02
                        </li>

                        <li style={{
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}>
                            Aza Kutu Yapıştırma
                        </li>

                        <a 
                        
                        target={"_blank"} href={"https://www.instagram.com/" + Constants.about_us_constants.contact.instagram_kutu.replace("@", "")}>
                            <li>
                            <InstagramIcon className="aza_footer_icon" fontSize="small" /> {Constants.about_us_constants.contact.instagram_kutu}
                            </li>
                        </a>

                        <a target={"_blank"} href={"https://www.facebook.com/" + Constants.about_us_constants.contact.facebook.replace("@", "")}>
                            <li>
                            <FacebookIcon className="aza_footer_icon" fontSize="small" /> @azakutuyapistirma
                            </li>
                        </a>
                    </ul>
                </Container>
            </Box>
            <Box width="100%" sx={{ display: { xs: "none", md: "none" } }}>
                <div className="aza_header_contact">
                    <Grid container maxWidth={"xl"} spacing={2}>
                        <Grid item>
                            <span className="text-secondary">
                            <PermPhoneMsgTwoToneIcon fontSize="small" className="primary-fj-text" style={{ marginRight: 5 }} /> 
                            0212 501 24 41
                            </span>
                        </Grid>

                        <Grid item>
                            <span className="text-secondary">
                            <PrintTwoToneIcon fontSize="small" className="primary-fj-text" style={{ marginRight: 5 }} /> 
                            0212 571 12 37
                            </span>
                        </Grid>

                        <Grid item>
                            <span className="text-secondary">
                            <InstagramIcon fontSize="small" className="primary-fj-text" style={{ marginRight: 5 }} /> 
                            azakutuyapistirma
                            </span>
                        </Grid>

                        <Grid item>
                            <span className="text-secondary">
                            <FacebookIcon fontSize="small" className="primary-fj-text" style={{ marginRight: 5 }} /> 
                            azakutuyapistirma
                            </span>
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <Container component={"div"} className="aza_header_container" maxWidth="xl">
                <Link style={{ cursor: "pointer" }} to="/">
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                        <AzaLogo w={220} h={70} />
                    </Box>

                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <AzaLogo w={190} h={60} />
                    </Box>
                </Link>
                <Box width="100%" sx={{ display: { xs: "none", md: "block" } }}>
                    <div className="aza_header_r">
                        <ul className="ul">
                            <Link to={"/"}>
                                <li className="header_btn">
                                    Ana Sayfa
                                    <span></span>
                                </li>
                            </Link>

                            <Link to="/hakkimizda">
                                <li className="header_btn">
                                    Hakkımızda
                                    <span></span>
                                </li>
                            </Link>

                            <Link to="/portfoy">
                                <li className="header_btn">
                                    Portföy
                                    <span></span>
                                </li>
                            </Link>


                            <Link to="/iletisim">
                                <li className="header_btn">
                                    İletişim
                                    <span></span>
                                </li>
                            </Link>
                            
                            <li style={{ marginLeft: "auto" }}>
                                <a target={"_blank"} /*  */>
                                <AzaButton 
                                buttonProps={{
                                    id: "basic-button",
                                    "aria-controls": open ? 'basic-menu' : undefined,
                                    "aria-haspopup": "true",
                                    "aria-expanded": open ? 'true' : undefined,
                                    onClick: (e) => handleClick(e)
                                }}                        
                                
                                startAdornment={<PublicIcon />}>
                                    Web Sitelerimiz
                                </AzaButton>
                                </a>
                            </li>

                            <li>
                            <ReactFlagsSelect
                                selected={selectedLang}
                                onSelect={(code) => setSelectedLang(code)}
                                fullWidth={false}
                                showSelectedLabel={false}
                                showOptionLabel={false}
                                className="header_flag_select"
                                countries={['TR', 'US', 'MX', 'FR', 'CN', 'JP']}
                            />
                            </li>
                        </ul>
                    </div>
                </Box>

                <Box marginLeft={"auto"} marginRight={0} sx={{ display: { xs: "block", md: "none" } }}>
                    <AzaButton buttonProps={{ onClick: toggleDrawer("right", true) }} startAdornment={<MenuIcon />} >
                    </AzaButton>
                </Box>
            </Container>
        </header>

        <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        >
            <Box width={250} role="presentation"
            onClick={toggleDrawer("right", false)}
            onKeyDown={toggleDrawer("right", false)}
            padding={2}
            >
                <Grid container spacing={2} direction="column">
                    <Grid item>
                        <AzaLogo variant="logo_1" />
                    </Grid>

                    <Grid item>
                        <Divider />
                    </Grid>

                    <Grid item className="aza_header_r_mobile">
                    <ul>
                        <Link to={"/"}>
                            <li className="header_btn">
                                Ana Sayfa
                                <span></span>
                            </li>
                        </Link>

                        <Link to="/hakkimizda">
                            <li className="header_btn">
                                Hakkımızda
                                <span></span>
                            </li>
                        </Link>

                        <Link to="/portfoy">
                            <li className="header_btn">
                                Portföy
                                <span></span>
                            </li>
                        </Link>


                        <Link to="/iletisim">
                            <li className="header_btn">
                                İletişim
                                <span></span>
                            </li>
                        </Link>

                        <Divider />
                        
                        <li style={{ width: "100%" }}>
                            <a target={"_blank"} href="http://www.azaticaret.com/">
                            <AzaButton buttonProps={{ style: { width: "100%" } }} startAdornment={<PublicIcon />}>
                                Aza Dış Ticaret
                            </AzaButton>
                            </a>
                        </li>
                    </ul>
                    </Grid>
                </Grid>                
            </Box>
        </SwipeableDrawer>

        <Menu
        PaperProps={{
            style:{
                padding: 12
            }
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        >
            <a target={"_blank"} href={"https://azaticaret.com/"}>
            <MenuItem style={{
                borderRadius: 8
            }}>
            Aza Dış Ticaret
            </MenuItem>
            </a>
            
            <a target={"_blank"} href={"https://azatradeshop.com/"}>
            <MenuItem style={{
                borderRadius: 8
            }}>
            Aza Trade Shop
            </MenuItem>
            </a>
            
            <a target={"_blank"} href={"https://alji.com.tr/"}>
            <MenuItem style={{
                borderRadius: 8
            }}>
            Alji
            </MenuItem>
            </a>
            
            
            <a target={"_blank"} href={"https://www.dincerbaydar.com/"}>
            <MenuItem style={{
                borderRadius: 8
            }}>
            Dinçer Baydar
            </MenuItem>
            </a>
        </Menu>
        </>
    )
}

export default Header;