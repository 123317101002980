import Constants from "../../Constants"

 
export interface IProduct{
    productID?: string;
    productName?: string;
    productDetailText?: string;
    productDescription?: string;
    productImages: string[];
}

type panelState = {
    products: IProduct[]
}

const initialState: panelState = {
    products: [
        {
            productID: "1",
            productName: "test",
            productImages: [
                ""
            ],
            productDescription: "test d",
            productDetailText: "test text"
        }
    ]
}

export default function panelReducer(state = initialState, action:any) {
    switch (action.type) {
        case Constants.store_constants.products.SET_PRODUCTS:
            return {...state, products: action.data }
        default:
            return state;
    }
}