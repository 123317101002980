import clsx from "clsx";
import { ReactHTMLElement, useEffect } from "react";
import { ThreeCircles } from "react-loader-spinner";
import AzaLogo from "./Elements/AzaLogo";

interface ILoader extends React.HTMLProps<HTMLDivElement>{
    loaded?: boolean;
}

function Loader(props:ILoader) {
    
    useEffect(() => {
        if(props.loaded){
            var el = document.getElementById(props.id!);
            if(el != null){
                el.classList.add("loader_inactive");
                setTimeout(() => {
                    el!.style.display = "none";
                }, 400);
            }
        }
    }, [props.loaded])

    return(
        <div {...props} className={clsx("loader")}>
            <AzaLogo w={340}  />
            <ThreeCircles
            height="85"
            width="85"
            color="#2F97C1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
            />
        </div>
    )
}

export default Loader;