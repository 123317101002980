import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { connect } from "react-redux";
import ProductShortcut from "../Components/ProductShortcut";
import { IProduct } from "../Utils/Store/reducers/panelReducer";


interface ProductsPageProps{
    products?: IProduct[];
}

function Products(props:ProductsPageProps) {
    const { products } = props;

    return(
        <section id="products">
            <Container maxWidth="xl">
                <h2>
                    Portföy
                </h2>
                <p className="text-secondary">
                Aktif sunduğumuz hizmetler
                </p>
                <hr color="#1CCAD8" style={{ width: "25%" }} />
                <Grid container spacing={3} marginTop={4}>
                    {products?.map((product:IProduct, index:number) => {
                        return(
                            <Grid item lg={3} xs={12} key={"porftoy_products_" + index}>
                                <ProductShortcut productItem={product}  />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </section>
    )
}

const mapStateToProps = (state:any) => ({
    products: state.panel.products
});

export default connect(mapStateToProps)(Products);