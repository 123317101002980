import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './reducers/index';
import thunkMiddleware from 'redux-thunk';



let middlewares = [
  thunkMiddleware
];

const store = createStore(
  rootReducer(),
  applyMiddleware(...middlewares),
);

export type AppDispatch = typeof store.dispatch

export { store };