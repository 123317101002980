import assets_logo_1 from "../../Assets/logo_1.png";
import assets_logo_2 from "../../Assets/logoazaticaret.png";
import assets_logo_3 from "../../Assets/logoazaticaret_2.png";


interface AzaLogoProps{
    variant?: "logo_1" | "logo_2" | "logo_3";
    w?: number | string;
    h?: number | string;
    size?: number
}

function AzaLogo(props:AzaLogoProps) {
    const { variant = "logo_1", w, h } = props;

    function GetLogoSrc(variant:string) {
        switch (variant) {
            case "logo_1":
                return assets_logo_1;
            case "logo_2":
                return assets_logo_2;
            case "logo_3":
                return assets_logo_3;
            default:
                return assets_logo_1
        }
    }


    return(
        <div
        className="aza_logo"
        style={{
            minWidth: w,
            minHeight: h,
            width: w,
            height: h
        }}
        >
            <img 
            src={GetLogoSrc(variant)} 
            alt={"aza_kutu_yapistirma_" + variant} 
            style={{
                width: "100%",
                height: "100%"
            }}
            />
        </div>
    )
}

export default AzaLogo;