import { Grid, Paper } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import PageTitle from "../Components/PageTitle";
import AllInboxIcon from '@mui/icons-material/AllInbox';
import LanguageIcon from '@mui/icons-material/Language';
import SellIcon from '@mui/icons-material/Sell';
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FaxIcon from '@mui/icons-material/Fax';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Directions, Mail, PunchClock, Streetview } from "@mui/icons-material";

function Contact() {

    const [contacts, setContacts] = useState([
        {

        }
    ]);
    
    const [state, setState] = useState({
        page: "kutu_yapistirma"
    });
    return(
        <>
        <PageTitle imageSrc="https://i.hizliresim.com/qgbjcb1.jpg" titleName="İletişim" />
        <section id="contact">
            <Container maxWidth="xl">
                
                <Grid container spacing={4} style={{ marginTop: 32 }}>
                    
                    <Grid container item lg={12} spacing={2} justifyContent="center">
                        <Grid item>
                            <div onClick={() => setState({...state, page: "kutu_yapistirma"})} className={clsx("contact_nav_btn", state.page == "kutu_yapistirma" &&  "contact_nav_btn_active")}>
                                <div className="icon">
                                    <AllInboxIcon />
                                </div>

                                <span>
                                    Aza - Kutu Yapıştırma
                                </span>
                            </div>
                        </Grid>

                        
                        <Grid item>
                            <div onClick={() => setState({...state, page: "merkez"})} className={clsx("contact_nav_btn", state.page == "merkez" &&  "contact_nav_btn_active")}>
                                <div className="icon">
                                    <LanguageIcon />
                                </div>

                                <span>
                                    Aza - Merkez
                                </span>
                            </div>
                        </Grid>

                        <Grid item>
                            <div onClick={() => setState({...state, page: "alji"})} className={clsx("contact_nav_btn", state.page == "alji" &&  "contact_nav_btn_active")}>
                                <div className="icon">
                                    <SellIcon />
                                </div>

                                <span>
                                    Alji
                                </span>
                            </div>
                        </Grid>
                    </Grid>

                    {state.page == "kutu_yapistirma" ? 
                        <Grid container item lg={12} spacing={3} style={{ marginTop: 32 }}>
                            <Grid container item lg={12} display="flex" flexDirection={"column"} textAlign="left">
                                <h2>İletişim - Kutu Yapıştırma</h2>
                                <hr color="#1CCAD8" style={{ width: "25%", marginLeft: 0 }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <div className="contact_box">
                                    <h3>
                                        Bizi Arayın
                                    </h3>
                                    <p>
                                    İhtiyacınız ne ise arayın size yardımcı olabilmek için detayları konuşalım.
                                    </p>
                                    <h4>
                                    <div className="icon">
                                        <LocalPhoneIcon />
                                    </div> 
                                    0212 501 24 41 – 0543 285 19 49
                                    </h4>
                                    <h4>
                                    <div className="icon">
                                        <FaxIcon />
                                    </div> 
                                    0212 571 12 37  
                                    </h4>
                                    <h4>
                                    <div className="icon">
                                        <ReceiptIcon />
                                    </div> 
                                    0212 571 3702
                                    </h4>
                                </div>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <div className="contact_box">
                                    <h3>
                                    Bizi Ziyaret Edin
                                    </h3>
                                    <p>
                                    Gelin projelerinizi daha detaylı konuşalım.
                                    </p>
                                    <h4 >
                                    <div className="icon">
                                        <Directions />
                                    </div> 
                                    Maltepe Mh. Davutpaşa cd. Emintaş Davutpaşa sitesi. No: 101/199 - 201. Zeytinburnu / İstanbul
                                    </h4>

                                    <h4>
                                    <div className="icon">
                                        <PunchClock />
                                    </div> 
                                    <ul>
                                        <li>
                                        Hafta İçi: Gündüz 8:30 - 19:00 | Gece 10:00 - 09:00
                                        </li>

                                        <li>
                                        Cumartesi: Gündüz 8:30 - 19:00 | Gece 10:00 - 09:00
                                        </li>

                                        <li>
                                        Pazar: Kapalı
                                        </li>
                                    </ul>
                                    </h4>
                                </div>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <div className="contact_box">
                                    <h3>
                                        Bize Yazın
                                    </h3>
                                    <p>
                                    Örnek görsel veya çalışma dosyası paylaşarak bizden ön çalışma talep edin.
                                    </p>
                                    <h4>
                                    <div className="icon">
                                        <Mail />
                                    </div> 
                                    kutu@azaticaret.com
                                    </h4>

                                    <h4>
                                    <div className="icon">
                                        <Mail />
                                    </div> 
                                    info@azaticaret.com
                                    </h4>
                                </div>
                            </Grid>

                            

                            <Grid item xs={12} lg={6}>
                                <div className="contact_box">
                                    <h3>
                                    Şirket Bilgileri
                                    </h3>
                                    <p>
                                    Şirketimize dair sicil bilgileri
                                    </p>
                                    <h4>
                                    Ticaret Sicil No: 387797-5
                                    </h4>
                                    <h4>
                                    Mersis No: 0299-0474-1400-0002
                                    </h4>
                                    <h4>
                                    Sermaye: 2.000.000TL
                                    </h4>

                                    <h4>
                                    Toplam Ödenmiş Sermaye: 7.000.000TL
                                    </h4>
                                </div>
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <div className="contact_box">
                                    <h3>
                                    Harita
                                    </h3>
                                    
                                    <object
                                    style={{ border: 0, width: "100%", height: 700 }}
                data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1220.7707103043713!2d28.913836826360807!3d41.02014089486355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba50f91012fd%3A0xa4fed7e533270b2!2sEminta%C5%9F%20Davutpa%C5%9Fa%20Sitesi!5e0!3m2!1str!2str!4v1666533793694!5m2!1str!2str"></object>
                                </div>
                            </Grid>
                        </Grid> : 
                        state.page == "merkez" ? 
                    
                        <Grid container item lg={12} spacing={3} style={{ marginTop: 32 }}>
                        <Grid container item lg={12} display="flex" flexDirection={"column"} textAlign="left">
                            <h2>İletişim - Merkez</h2>
                            <hr color="#1CCAD8" style={{ width: "25%", marginLeft: 0 }} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                    Bizi Arayın
                                </h3>
                                <p>
                                İhtiyacınız ne ise arayın size yardımcı olabilmek için detayları konuşalım.
                                </p>
                                <h4>
                                <div className="icon">
                                    <LocalPhoneIcon />
                                </div> 
                                0212 571 41 17 - 0212 571 37 02 - 0212 571 12 37
                                </h4>
                                <h4>
                                <div className="icon">
                                    <FaxIcon />
                                </div> 
                                0212 571 12 37
                                </h4>
                                <h4>
                                <div className="icon">
                                    <ReceiptIcon />
                                </div> 
                                0212 571 3702
                                </h4>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                Bizi Ziyaret Edin
                                </h3>
                                <p>
                                Gelin projelerinizi daha detaylı konuşalım.
                                </p>
                                <h4 style={{ maxWidth: 500 }}>
                                <div className="icon">
                                    <Directions />
                                </div>
                                Kartaltepe mh. Pelinli sk. Ömürsaray apt. 2. blok no: 8/1/28 Bakırköy/İstanbul
                                </h4>
                                <h4>
                                    <div className="icon">
                                        <PunchClock />
                                    </div> 
                                    <ul>
                                        <li>
                                        Hafta İçi: 09:00 - 19:00
                                        </li>

                                        <li>
                                        Hafta Sonu: 10:00 - 16:00
                                        </li>

                                        <li>
                                        Pazar: Kapalı
                                        </li>
                                    </ul>
                                </h4>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                    Bize Yazın
                                </h3>
                                <p>
                                Örnek görsel veya çalışma dosyası paylaşarak bizden ön çalışma talep edin.
                                </p>
                                <h4>
                                <div className="icon">
                                    <Mail />
                                </div> 
                                info@azaticaret.com
                                </h4>
                            </div>
                        </Grid>

                        

                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                Şirket Bilgileri
                                </h3>
                                <p>
                                Şirketimize dair sicil bilgileri
                                </p>
                                <h4>
                                Ticaret Sicil No: 310729 - 5
                                </h4>
                                <h4>
                                Mersis No: 0299 - 0474 - 1400 - 0001
                                </h4>

                                <h4>
                                Sermaye: 3.000.000TL
                                </h4>
                                <h4>
                                Toplam Ödenmiş Sermaye: 7.000.000TL
                                </h4>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <div className="contact_box">
                                <h3>
                                Harita
                                </h3>
                                
                                <object
                                style={{ border: 0, width: "100%", height: 700 }}
                                data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2255.9817347151275!2d28.872351177544303!3d40.99518117791125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabb5ac84b6b07%3A0x2aaeadf8383b3013!2zS2FydGFsdGVwZSwgUGVsaW5saSBTay4sIDM0MTQ1IEJha8SxcmvDtnkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1666216029273!5m2!1str!2str"></object>
                            </div>
                        </Grid>
                    </Grid>
                    :
                    state.page=="alji" &&
                    <Grid container item lg={12} spacing={3} style={{ marginTop: 32 }}>
                        <Grid container item lg={12} display="flex" flexDirection={"column"} textAlign="left">
                            <h2>İletişim - Elektronik Alji Şube</h2>
                            <hr color="#1CCAD8" style={{ width: "25%", marginLeft: 0 }} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                    Bizi Arayın
                                </h3>
                                <p>
                                İhtiyacınız ne ise arayın size yardımcı olabilmek için detayları konuşalım.
                                </p>
                                <h4>
                                <div className="icon">
                                    <LocalPhoneIcon />
                                </div> 
                                0212 571 41 17 - 0533 712 24 26
                                </h4>
                                <h4>
                                <div className="icon">
                                    <FaxIcon />
                                </div> 
                                212 571 12 37
                                </h4>
                                <h4>
                                <div className="icon">
                                    <ReceiptIcon />
                                </div> 
                                0212 571 3702
                                </h4>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                Bizi Ziyaret Edin
                                </h3>
                                <p>
                                Gelin projelerinizi daha detaylı konuşalım.
                                </p>
                                <h4 style={{ maxWidth: 500 }}>
                                <div className="icon">
                                    <Directions />
                                </div>
                                Maltepe Mh. Davutpaşa cd. Emintaş Davutpaşa sitesi. no:101/201 zeytiburnu/istanbul
                                </h4>

                                <h4>
                                    <div className="icon">
                                        <PunchClock />
                                    </div> 
                                    <ul>
                                        <li>
                                        Hafta İçi: 8:30 - 19:00 
                                        </li>

                                        <li>
                                        Cumartesi: 8:30 - 19:00
                                        </li>

                                        <li>
                                        Pazar: Kapalı
                                        </li>
                                    </ul>
                                    </h4>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                    Bize Yazın
                                </h3>
                                <p>
                                Örnek görsel veya çalışma dosyası paylaşarak bizden ön çalışma talep edin.
                                </p>
                                <h4>
                                <div className="icon">
                                    <Mail />
                                </div> 
                                info@azaticaret.com
                                </h4>
                            </div>
                        </Grid>

                        

                        <Grid item xs={12} lg={6}>
                            <div className="contact_box">
                                <h3>
                                Şirket Bilgileri
                                </h3>
                                <p>
                                Şirketimize dair sicil bilgileri
                                </p>
                                <h4>
                                Ticaret Sicil No: 400763-5
                                </h4>
                                <h4>
                                Mersis No: 0299-0474-1400-0003
                                </h4>

                                <h4>
                                Sermaye: 2.000.000TL
                                </h4>

                                <h4>
                                Toplam Ödenmiş Sermaye: 7.000.000TL
                                </h4>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={12}>
                            <div className="contact_box">
                                <h3>
                                Harita
                                </h3>
                                
                                <object
                                style={{ border: 0, width: "100%", height: 700 }}
                                data="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2255.9817347151275!2d28.872351177544303!3d40.99518117791125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cabb5ac84b6b07%3A0x2aaeadf8383b3013!2zS2FydGFsdGVwZSwgUGVsaW5saSBTay4sIDM0MTQ1IEJha8SxcmvDtnkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1666216029273!5m2!1str!2str"></object>
                            </div>
                        </Grid>
                    </Grid>}

                    

                    
                  
                </Grid>
            </Container>
        </section>
        
        </>
    )
}

export default Contact;