import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";

import partner_logo_1 from "../Assets/partners_logo/partner_logo_1.jpg";
import partner_logo_2 from "../Assets/partners_logo/partner_logo_2.png";
import partner_logo_3 from "../Assets/partners_logo/partner_logo_3.jpg";
import partner_logo_4 from "../Assets/partners_logo/partner_logo_4.png";
import partner_logo_5 from "../Assets/partners_logo/partner_logo_5.png";
import partner_logo_6 from "../Assets/partners_logo/partner_logo_6.png";
import partner_logo_7 from "../Assets/partners_logo/partner_logo_7.jpg";

export default function PartnersSection() {

    const [partners, setPartners] = useState([
        {
            url: partner_logo_1
        },

        {
            url: partner_logo_2
        },

        {
            url: partner_logo_3
        },

        {
            url: partner_logo_4
        },
        
        {
            url: partner_logo_5
        },

        {
            url: partner_logo_7
        }
    ])
    

    return(
        <section className="section_2">
            <Container maxWidth="xl" style={{ display: "flex", flexDirection: "column" }}>
                <h2 style={{ color: "#333", margin: 0, marginBottom: 12 }}>
                    Referanslarımız
                </h2>
                <span className="text-secondary">
                    Bizimle çalışmayı tercih edenler
                </span>
                <hr color="#1CCAD8" style={{ width: "25%" }} />
                <div className="partners_section">
                    <Grid container spacing={3} alignItems="center">
                        {partners.map((item, index) => {
                            
                            return(
                                <>
                                <Grid item xs={12} lg={2}>
                                    <div className="partners_section_item">
                                        <img src={item.url} alt="" />
                                    </div>
                                </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </div>
            </Container>
        </section>
    )
}