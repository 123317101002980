
export const UserComments = [
    {
        name: "Cansu Marmara",
        mission: "CEO",
        image: "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg",
        title: "Gerçekten şahane bir hız ve deneyim",
        description: "işe başlarken her ne kadar tedirgin olsakta süreçte ellerinden gelenin en iyisini yaptılar ve sonuçta mükemmel bir iş çıktı ortaya"
    },

    {
        name: "Hakan Güneş",
        mission: "Birsen Kolonya Yönetici",
        image: "https://st3.depositphotos.com/4111759/13425/v/600/depositphotos_134255710-stock-illustration-avatar-vector-male-profile-gray.jpg",
        title: "Tek Kelime İle Mükemmel Hizmet",
        description: "gerek ilgilenmeleri gerekse işlerine gösterdikleri özen size sadece izleme keyfini bırakıyor arkanıza yaslanıp alacağınız mükemmel hizmeti izliyorsunuz sadece."
    }
]