import { store } from "../store"


function GetProductDetails(prodId:string) {
    var state = store.getState();
    var product = state.panel.products.find((x:any) => x.productID == prodId);
    return product;
}

function GetHomePageProducts() {
    const state = store.getState();
    return state.panel.products;
}

function GetSuggestProducts(len:number) {
    const state = store.getState();
    return state.panel.products.slice(0, len);
}

export {
    GetProductDetails,
    GetHomePageProducts,
    GetSuggestProducts
}