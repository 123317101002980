
export const about_us_constants = {
    contact: {
        phone_1: "",
        phone_2: "",
        instagram_aza: "@azakutuyapistirma",
        instagram_kutu: "@aza_kutu_yapistirma",
        facebook: "@kutuyapistirmaaza",
        tiktok: ""
    }
}