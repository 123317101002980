import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";

interface AzaButtonProps{
    children?: React.ReactNode;
    fullWidth?:  boolean;
    startAdornment?: React.ReactNode;
    iconButton?: boolean;
    buttonProps?: React.HTMLAttributes<HTMLButtonElement>
}

function AzaButton(props:AzaButtonProps) {
    const { children, iconButton } = props;

    return(
        <button 
        {...props.buttonProps}
        className={clsx("aza_button", (props.startAdornment !== undefined && props.startAdornment !== null) && "aza_button_outline"  )}>
            {props.startAdornment !== undefined && props.startAdornment !== null && (
                <>
                <div
                style={{
                    marginRight: props.children ? 8 : 0,
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    {props.startAdornment}
                </div>

                <div
                style={{
                    marginRight: 0,
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    {props.children}
                </div>
                </>
            )}

            {(props.startAdornment === undefined || props.startAdornment === null && props.iconButton === true) && (
                <div 
                style={{
                    marginRight: 0,
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    {props.children}
                </div>
            )}

        </button>
    )
}

export default AzaButton;