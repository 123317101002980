import { Container, Grid } from "@mui/material";
import AzaLogo from "../Components/Elements/AzaLogo";


function TaxDetails() {
    

    return(
        <section id="about_us">
            <Container maxWidth="md">

                <h2>Merkez Bilgileri</h2>
                <hr color="#1CCAD8" style={{ width: "25%" }} />

                <Grid container spacing={3} style={{ marginTop: 32 }}>
                    <Grid item xl={4}>
                        <div className="contact_box">
                            <h3>
                                Tic. Sicil No.
                            </h3>
                            
                            <h4>
                            310729 - 5
                            </h4>
                        </div>
                    </Grid>
                    
                    <Grid item xl={4}>
                        <div className="contact_box">
                            <h3>
                                Mersis No.
                            </h3>
                            <h4>
                            0299 - 0474 - 1400 - 0001
                            </h4>
                        </div>
                    </Grid>

                    <Grid item xl={4}>
                        <div className="contact_box">
                            <h3>
                                Sermaye
                            </h3>
                            <h4>
                            3.000.000 (Üç milyon) TL
                            </h4>
                        </div>
                    </Grid>
                </Grid>

                <h2>Atölye Bilgileri</h2>
                <hr color="#1CCAD8" style={{ width: "25%" }} />

                <Grid container spacing={3} style={{ marginTop: 32 }}>
                    <Grid item xl={4}>
                        <div className="contact_box">
                            <h3>
                                Tic. Sicil No.
                            </h3>
                            
                            <h4>
                            387797 - 5
                            </h4>
                        </div>
                    </Grid>
                    
                    <Grid item xl={4}>
                        <div className="contact_box">
                            <h3>
                                Mersis No.
                            </h3>
                            <h4>
                            0299 - 0474 - 1400 - 0002
                            </h4>
                        </div>
                    </Grid>

                    <Grid item xl={4}>
                        <div className="contact_box">
                            <h3>
                                Sermaye
                            </h3>
                            <h4>
                            2.000.000 (İki milyon) TL
                            </h4>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}


export default TaxDetails;