import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import Header from './Components/Header';
import Home from './Views/Home';
import Footer from './Components/Footer';
import { Route, Switch } from 'react-router-dom';
import ProductDetails from './Views/ProductDetail';
import AboutUs from './Views/AboutUs';
import Products from './Views/Products';
import Contact from './Views/Contact';
import Loader from './Components/Loader';
import TaxDetails from './Views/TaxDetails';
import ScrollToTop from './Components/ScrollToTop';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './Utils/Store/store';
import Constants from './Utils/Constants';
import { products } from './Utils/fakeDatabase/products';


function App() {
  
  const [loaded, setLoaded] = useState(false);
  const dispatch:AppDispatch = useDispatch();

  useEffect(() => {
    var productsDb = products;
    dispatch({
      type: Constants.store_constants.products.SET_PRODUCTS,
      data: productsDb
    });
    setTimeout(() => {
      setLoaded(true)
    }, 1500);
  }, []);

  return (
    <>
    <Loader loaded={loaded} id="main_loader" />
    <ScrollToTop />
    <Header />
    <main id='main_content'>
        <Route exact path='/'>
          <Home />
        </Route>

        <Route exact path='/portfoy'>
          <Products />
        </Route>

        <Route exact path='/portfoy/:portfoyid'>
          <ProductDetails />
        </Route>

        <Route path='/hakkimizda'>
          <AboutUs />
        </Route>

        <Route path="/iletisim">
          <Contact />
        </Route>

        <Route path="/vergi">
          <TaxDetails />
        </Route>
    </main>
    <Footer />
    </>
  );
}

export default App;
