import React from "react"
import { motion } from "framer-motion";

interface PageTitleProps{
    titleName: string;
    imageSrc: string;
}

export default function PageTitle(props:PageTitleProps) {
    
    return(
        <section className="page-title-props">
            <motion.span
            /*
            initial={{
                y: -1000,
                x: "-50%",
                opacity: 0
            }}
            animate={{
                y: 0,
                opacity: 1,
                transitionDuration: "500ms",
                transitionDelay: "200ms"
            }}
             */
            >
            {props.titleName}
            </motion.span>
            <div className="page-title-props-bg"></div>
            <img src={props.imageSrc} alt="" />
        </section>
    )
}