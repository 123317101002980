

export const products = [
    {
        "productID": "a37d66b2-55f9-11ed-bdc3-0242ac120002",
        "productName": "Otomatik Çift Taraflı Bant Yapıştırma",
        "productDescription": `
        Çift taraflı bant yapıştırma ihtiyacı duyulan etiket, broşür, poster, afiş, ilaç kutusu, açık-kapalı kutulara çift taraflı bant yapıştırma işleri otomatik ve stabil şekilde yapılır.
        Tiraj sınırlaması olmaksızın her nevi kağıt üzerine makinemize sığdığı ölçülerde çift taraflı bant yapıştırıyoruz. Adet ve ebat belirterek bizden fiyat teklifi alabilirsiniz.
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2020/06/cift-tarafli-bant-768x768.jpg"
        ]
    },

    {
        "productID": "32015c18-55fa-11ed-bdc3-0242ac120002",
        "productName": "İlaç Kutusu Dip Kilit Yapıştırma",
        "productDescription": `
        
        `,
        "productDetailText": "İlaç Kutusu Yapıştırma",
        "productImages": [
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2019/03/IMG_20190306_220641-768x768.jpg",
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2019/03/IMG_20190306_220544-768x768.jpg",
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2019/03/IMG_20190306_220650-768x768.jpg"
        ]
    },

    {
        "productID": "6032416a-55fa-11ed-bdc3-0242ac120002",
        "productName": "Dip Kilit Yapıştırma",
        "productDescription": `
        Kutu Dip Kilit ve Yan yapıştırma işlemi
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2017/01/IMG_20190306_213315-768x768.jpg",
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2017/01/IMG_20190306_213436-768x768.jpg",
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2017/01/IMG_20190306_213520-768x768.jpg"
        ]
    },

    {
        "productID": "61b4b05c-55fc-11ed-bdc3-0242ac120002",
        "productName": "Micro Oluklu Kutu Dip Kilit Yapıştırma",
        "productDescription": `
        Dip Kilit Micro Oluklu Kutu Yapıştırma
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2017/01/IMG_20190306_215424-768x768.jpg",
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2017/01/IMG_20190306_215331-768x768.jpg",
            "http://www.saraykutuyapistirma.com.tr/wp-content/uploads/2017/01/IMG_20190306_215338-768x768.jpg"
        ]
    },

    /* */

    {
        "productID": "a37d66b2-55f9-11ed-bdc3-0242ac1200021",
        "productName": "Hediye Zarfı",
        "productDescription": `
     
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/lymnsp3.jpg"
        ]
    },

    {
        "productID": "32015c18-55fa-11ed-bdc3-0242ac1200021",
        "productName": "Yan Yapıştırma",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/al46pi1.jpg"
        ]
    },

    {
        "productID": "6032416a-55fa-11ed-bdc3-0242ac1200021",
        "productName": "Külah",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/shoo5ng.png"
        ]
    },

    {
        "productID": "61b4b05c-55fc-11ed-bdc3-0242ac1200021",
        "productName": "4 Nokta Kutu Yapıştırma",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/j14c3r7.jpg"
        ]
    },

    {
        "productID": "61b4b05c-55fc-11ed-bdc3-0242ac1200022",
        "productName": "Çanta",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/kikhmbb.jpeg"
        ]
    },

    {
        "productID": "61b4b05c-55fc-11ed-bdc3-0242ac1200023",
        "productName": "Fantezi Kutu",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/1r6u35a.jpeg"
        ]
    },

    {
        "productID": "61b4b05c-55fc-11ed-bdc3-0242ac1200024",
        "productName": "CD Zarf",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/58nffo0.jpeg"
        ]
    },

    {
        "productID": "61b4b05c-55fc-11ed-bdc3-0242ac1200025",
        "productName": "Aydınger Zarf",
        "productDescription": `
        
        `,
        "productDetailText": "İletişime geçerek detaylara ulaşabilirsiniz",
        "productImages": [
            "https://i.hizliresim.com/qxs24on.jpeg"
        ]
    },
]
