import { ArrowRightAltOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { IProduct } from "../Utils/Store/reducers/panelReducer";
import { generateUUID } from "../Utils/utils";
import AzaButton from "./Elements/Button/AzaButton";

interface ProductShortcutProps{
    productItem: IProduct;
}

function ProductShortcut(props:ProductShortcutProps) {
    const { productItem } = props;

    return(
        <div className="aza_product_">
            <img src={productItem.productImages![0]} alt="" />
            <span>
            {productItem.productName}
            </span>
            <Link to={"/portfoy/"+ productItem.productID} style={{ all: "unset", width: "100%" }}>
                <AzaButton buttonProps={{
                    style: {
                        width: "100%"
                    }
                }} startAdornment={<ArrowRightAltOutlined />}>
                    İncele
                </AzaButton>
            </Link>
        </div>
    )
}

export default ProductShortcut;