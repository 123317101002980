import { Container, Grid } from "@mui/material";
import AzaLogo from "../Components/Elements/AzaLogo";
import PageTitle from "../Components/PageTitle";
import { motion } from "framer-motion";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { employess } from "../Utils/fakeDatabase/employess";
import { Link } from "react-router-dom";
import PartnersSection from "../Components/PartnersSection";
import AzaButton from "../Components/Elements/Button/AzaButton";

function AboutUs() {
    

    return(
        <>
        <PageTitle imageSrc="https://i.hizliresim.com/i9fc2r4.jpg" titleName="Hakkımızda" />
        <section id="about_us">
            <Container maxWidth="xl">
                
                

                <Grid container spacing={4} direction="column">
                    <Grid container item spacing={3} >
                        <Grid item lg={5}>
                            <motion.div 
                            initial={{
                                x: -100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "800ms",
                                transitionDelay: "400ms"
                            }}
                            className="about_us_img">
                                <img src="https://i.hizliresim.com/2ytaic8.png" alt="" />
                            </motion.div>
                        </Grid>
                        
                        <Grid container direction="column" item lg={7}>
                            <Grid item>
                            <motion.h2
                            initial={{
                                x: 100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "800ms",
                                transitionDelay: "400ms"
                            }}
                            >Hakkımızda</motion.h2>
                            <motion.hr 
                            initial={{
                                x: 100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "800ms",
                                transitionDelay: "400ms"
                            }}
                            color="#1CCAD8" style={{ width: "100%", marginTop: 16, marginBottom: 16, marginLeft: 0 }} />
                            </Grid>
                            <Grid item>
                            <motion.p 
                            initial={{
                                x: 100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "1000ms",
                                transitionDelay: "400ms"
                            }}
                            style={{ fontSize: 18 }}>
                            1996 Yılından bugüne gelen tecrübemizle saatlik 60.000 adet 
                            üzerinde kutu yapıştırma kapasitesi ile ( Yan yapıştırma, ilaç 
                            kutuları, 4 ve 6 nokta yapıştırma, dip kilit, z katlama, otomatik 
                            dolum yapılan kutuların kolay açılması için çaka işlemleri, zarf 
                            yapıştırma, cd zarfı yapıştırma) tecrübeye sahip ekibimiz ile 
                            profesyonel bir şekilde 2019 model makinamızla siz değerli 
                            müşterilerine hizmet vermekteyiz.
                        
                            </motion.p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container textAlign={"right"} item spacing={3} >
                        <Grid container direction="column" item lg={7}>
                            <Grid item>
                            <motion.h2
                            initial={{
                                x: -100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "800ms",
                                transitionDelay: "1000ms"
                            }}
                            >Çalışma şeklimizle fark yaratıyoruz!</motion.h2>
                            <motion.hr 
                            initial={{
                                x: -100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "800ms",
                                transitionDelay: "1000ms"
                            }}
                            color="#1CCAD8" style={{ width: "100%", marginTop: 16, marginBottom: 16, marginRight: 0 }} />
                            </Grid>
                            <Grid item>
                            <motion.p 
                            initial={{
                                x: -100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "1000ms",
                                transitionDelay: "1000ms"
                            }}
                            style={{ fontSize: 18 }}>
                            Önce sizi dinliyor, isteğiniz doğrultusunda ihtiyacınız olan 
                            doğru çözümü uygun maliyetle sunuyoruz. Zamanınızın 
                            değerli olduğunun farkındayız ve söz verdiğimiz zamanda 
                            işimize başlıyor ve gereksiz zaman kayıplarını önlüyoruz. İşleri 
                            büyük/küçük diye ayırt etmeden güler yüzle yapıyoruz. 
                            Yaptığımız işlerin arkasında duruyor, yapıştırma işlemi sonrası sorularnızı yanıtlıyor , gerektiğinde müdahale ederek 
                            mağdur olmanızı önlüyor, mutlu müşteriler yaratmak için 
                            çalışıyoruz. Biliyoruz ki başarı yapılan iş sayısında değil hakkıyla yapılan iş sonucu memnun müşterilerimizin bizi tavsiye 
                            etmesidir.
                        
                            </motion.p>
                            </Grid>
                        </Grid>

                        <Grid item lg={5}>
                            <motion.div
                            initial={{
                                x: 100,
                                opacity: 0
                            }}
                            animate={{
                                x: 0,
                                opacity: 1,
                                transitionDuration: "800ms",
                                transitionDelay: "1000ms"
                            }}
                            className="about_us_img">
                                <img src="https://i.hizliresim.com/g3azmtq.jpg" alt="" />
                            </motion.div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>

        <section className="section_1" style={{ marginTop: 50, paddingBottom: 50 }}>
            <Container maxWidth="xl" style={{ display: "flex", flexDirection: "column" }}>
                <h2 style={{ color: "#333", margin: 0, marginBottom: 8 }}>
                    Ekibimiz
                </h2>
                <span className="text-secondary">
                    Bizi Yakından Tanıyın
                </span>
                <hr color="#1CCAD8" style={{ width: "25%" }} />

                <Grid container spacing={2} marginTop={4}>
                    {employess.map((item, index) => {
                        return(
                            <Grid 
                            component={motion.div}
                            /*initial={{
                                y: 50,
                                opacity: 0
                            }}
                            animate={{
                                y: 0,
                                opacity: 1,
                                transitionDuration: "1000ms",
                                transitionDelay: 1000 + (100 * index) + "ms"
                            }}*/
                            item xs={12} sm={12} lg={3} key={"employee_list_about_us_" + index}>
                                <div className="friends_card">
                                    <div className="avatar">
                                        <img src={item.avatarUrl} alt="avatar_employee" />
                                    </div>

                                    <div className="name">
                                        {item.name}
                                    </div>

                                

                                    <div className="mission">
                                        {item.mission}
                                    </div>

                                    <hr color="#1CCAD8" style={{ width: "50%", marginTop: 16, marginBottom: 16, marginLeft: "auto", marginRight: "auto" }} />

                                    <p>
                                        {item.description}
                                    </p>

                                    <div className="social-media">
                                        <Link to={"mailto:" + item.mail} target="_blank">
                                            <AzaButton>
                                                Mail Yolla
                                            </AzaButton>
                                        </Link>
                                    </div>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </section>

        <PartnersSection />

        </>
    )
}

/*
<Grid container spacing={2}>
                    <Grid item sm={12} lg={4}>
                        
                        <div className="com_documents">
                            <h2>
                                Vergi Levhası
                            </h2>

                            <div className="com_documents_inner_img">
                                <a target={"_blank"} href={require("../Assets/comp_docs/vergi_levhası.jpg")}>
                                    <img src={require("../Assets/comp_docs/vergi_levhası.jpg")} alt="" />    
                                </a>
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={12} lg={4}>
                        <div className="com_documents">
                            <h2>
                                Kobi Belgesi
                            </h2>

                            <div className="com_documents_inner_img">
                                <a target={"_blank"} href={require("../Assets/comp_docs/kobi_belgesi_1.jpg")}>
                                    <img src={require("../Assets/comp_docs/kobi_belgesi_1.jpg")} alt="" />    
                                </a>
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={12} lg={4}>
                        <div className="com_documents">
                            <h2>
                                Aza Dış Ticaret Açılış Gazetesi
                            </h2>

                            <div className="com_documents_inner_img">
                                <a target={"_blank"} href={require("../Assets/comp_docs/aza_dis_ticaret_acilis_gazetesi_1.jpg")}>
                                    <img src={require("../Assets/comp_docs/aza_dis_ticaret_acilis_gazetesi_1.jpg")} alt="" />    
                                </a>
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={12} lg={4}>
                        <div className="com_documents">
                            <h2>
                                Aza Dış Ticaret Açılış Gazetesi - 2
                            </h2>

                            <div className="com_documents_inner_img">
                                <a target={"_blank"} href={require("../Assets/comp_docs/aza_dis_ticaret_acilis_gazetesi_2.jpg")}>
                                    <img src={require("../Assets/comp_docs/aza_dis_ticaret_acilis_gazetesi_2.jpg")} alt="" />    
                                </a>
                            </div>
                        </div>
                    </Grid>

                </Grid>
*/


/*

<hr color="#1CCAD8" style={{ width: "25%", marginTop: 16, marginBottom: 16, marginLeft: 0 }} />

                <h2>Şirket Bilgileri</h2>

                <Grid container spacing={3} >
                    <Grid item xl={2}>
                        <div className="contact_box">
                            <h3>
                                Tic. Sicil No.
                            </h3>
                            
                            <h4>
                                387797 - 5
                            </h4>
                        </div>
                    </Grid>

                    <Grid item xl={2}>
                        <div className="contact_box">
                            <h3>
                                Mersis No.
                            </h3>
                            <h4>
                            0299 - 0474 - 1400 - 0002
                            </h4>
                        </div>
                    </Grid>

                    <Grid item xl={2}>
                        <div className="contact_box">
                            <h3>
                                Sermaye
                            </h3>
                            <h4>
                            2.000.000 (İki milyon) TL
                            </h4>
                        </div>
                    </Grid>
                </Grid>
*/

export default AboutUs;