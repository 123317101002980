import { AddBoxOutlined, ArrowRightAltOutlined, ComputerOutlined, ContactPhone, ContactPhoneOutlined, FaceOutlined, LockClockOutlined, Phone, ReviewsOutlined } from "@mui/icons-material";
import { Divider, Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import home_video from "../Assets/home_video.mp4";
import AzaButton from "../Components/Elements/Button/AzaButton";
import GalleryCarousel from "../Components/GalleryCarousel";
import YoutubeEmbed from "../Components/YoutubeEmbed";
import { motion } from "framer-motion";
import home_resim_1 from "../Assets/showroom/home_resim_1.jpeg";
import home_resim_2 from "../Assets/showroom/home_resim_2.jpeg";
import home_resim_3 from "../Assets/showroom/home_resim_3.jpeg";
import home_resim_4 from "../Assets/showroom/home_resim_4.jpg";
import home_resim_5 from "../Assets/showroom/home_resim_5.jpeg";
import home_resim_6 from "../Assets/showroom/home_resim_6.jpeg";
import home_resim_7 from "../Assets/showroom/home_resim_7.jpeg";
import home_resim_8 from "../Assets/showroom/home_resim_8.jpeg";
import home_resim_9 from "../Assets/showroom/home_resim_9.jpeg";
import home_resim_10 from "../Assets/showroom/home_resim_10.jpeg";
import home_resim_11 from "../Assets/showroom/home_resim_11.jpeg";

import { Link } from "react-router-dom";
import ProductShortcut from "../Components/ProductShortcut";
import { GetHomePageProducts, GetProductDetails } from "../Utils/Store/functions";
import { IProduct } from "../Utils/Store/reducers/panelReducer";
import PartnersSection from "../Components/PartnersSection";
import Carousel from "react-material-ui-carousel";
import { UserComments } from "../Utils/fakeDatabase/userComments";

function Home() {
    
    const [suggestProducts, setSuggestProducts] = useState<IProduct[]>([]);

    const [galleryItems, setGalleryItems] = useState(
        [
            home_resim_11,
            home_resim_1,
            home_resim_2,
            home_resim_3,
            home_resim_5,
            home_resim_6,
            home_resim_7,
            home_resim_8,
            home_resim_9,
            home_resim_10
        ]
    );

    useEffect(() => {
        setTimeout(() => {
            setSuggestProducts(GetHomePageProducts());
        }, 1000);
    }, []);

    return(
        <>
        <section id="home_landing_section" style={{ minHeight: 800 }}>
            <div className="home_landing_section_bg">
            </div>
            <Container maxWidth="xl" style={{ zIndex: 99, display: "flex", alignItems: "center" }}>
                <motion.div 
                initial={{
                    x: -900,
                    opacity: 0
                }}
                animate={{
                    x: 0,
                    opacity: 1,
                    transitionDuration: "800ms",
                    transitionDelay: "1600ms"
                }}
                id="home_landing_section_motd" style={{ position: "absolute" }}>
                    <h1 style={{ fontSize: 48 }}>
                        <span className="s_text">Yılların</span> tecrübesi ile çalışıyoruz
                    </h1>
                    <hr color="#1CCAD8" />
                    <span className="description">
                        <div>
                        1996 Yılından bu yana tecrübemiz ile her türlü kutu yapıştırma işinizi hakkıyla yaparız, başarı yapılan iş sayısında değil hakkıyla yapılan iş sonucu memnun müşterilerimizdir
                        </div>
                    </span> 

                    <Link to={"/iletisim"}>
                        <AzaButton 
                        startAdornment={<Phone />}
                        buttonProps={{
                            style: { width: "100%", marginTop: 8 }
                        }}>
                            İletişime Geç
                        </AzaButton>
                    </Link>
                </motion.div>
            </Container>

            <video style={{ }} autoPlay loop playsInline muted={true}>
                <source src={home_video} type="video/mp4" />
            </video>

            
        </section>

        <section id="aza_stats_section">
            <section className="pattern_section"></section>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item lg={3} xs={12}>
                        <div className="aza_stats">
                            <LockClockOutlined style={{ fontSize: 50 }} className="primary-fj-text" />

                            <h3>
                                26 Yıllık Tecrübe  
                            </h3>

                            <hr color="#1CCAD8" />

                            <span>
                            1996 yılından bugüne gelen tecrübemizle saatlik 60.000 adet üzerinde kutu yapıştırma kapasitesi ile müşterilerimize hizmet vermekteyiz.
                            </span>
                        </div>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                        <div className="aza_stats">
                            <ComputerOutlined style={{ fontSize: 50 }} className="primary-fj-text" />

                            <h3>
                                Modern Teknoloji
                            </h3>

                            <hr color="#1CCAD8" />

                            <span>
                            2019 Model 110'luk Dinçer makina, Green Gluing 
                            <b><a style={{ marginLeft: 5, cursor: "pointer" }} target="_blank" href={require("../Assets/docs/Leaflet Concept B_tr.pdf")}>Concept B</a></b> ve 
                            <b><a style={{ marginLeft: 5, cursor: "pointer" }} target="_blank" href={require("../Assets/docs/Leaflet SpeedStar Compact_tr.pdf")}>Speedstar Compact</a></b> ile üretimimize hız ve mükemmellik katıyoruz.
                            </span>
                        </div>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                        <div className="aza_stats">
                            <AddBoxOutlined style={{ fontSize: 50 }} className="primary-fj-text" />

                            <h3>
                                Çeşitli Portföy
                            </h3>

                            <hr color="#1CCAD8" />

                            <span>
                                Yüzlerce farklı ürün ile çeşitliliğimizi ortaya koyuyor, sınırımız olmadığını gösteriyoruz.
                            </span>
                        </div>
                    </Grid>

                    <Grid item lg={3} xs={12}>
                        <div className="aza_stats">
                            <FaceOutlined style={{ fontSize: 50 }} className="primary-fj-text" />

                            <h3>
                                Mutlu Müşteriler
                            </h3>

                            <hr color="#1CCAD8" />

                            <span>
                                Tek referansımız, mutlu müşterilerimiz.
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>

        

        <section className="section_2" style={{ paddingBottom: 48 }}>
            <Container maxWidth="xl">
                <h2>
                    Örnek Projelerimiz - Neler Yapıyoruz
                </h2>

                <hr color="#1CCAD8" style={{ width: "10%" }} />

                <p className="text-secondary">
                Daha önceki projelerimizde sadece bazıları, daha fazlası için portfoy sayfasını ziyaret et.
                </p>

                <Grid container spacing={2}>
                    {suggestProducts.map((product, index) => {
                        return(
                            <Grid item xs={12} lg={3} key={"suggest_product_" + index}>
                                <ProductShortcut productItem={product} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </section>

        <section className="section_1">
            <Container maxWidth="xl">
                <h2>
                    Çalışma Videoları
                </h2>

                <hr color="#1CCAD8" style={{ width: "10%" }} />

                <p className="text-secondary">
                Çalışma ortamımızdan ve kullandığımız makinelerden bir kaç video.
                </p>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <YoutubeEmbed height={"680"} embedId="AmBPVT7P9e8" />
                    </Grid>

                    <Grid container spacing={2} item xs={12}>
                        <Grid item lg={3} xs={12}>
                            <YoutubeEmbed embedId="AmBPVT7P9e8" />
                        </Grid>

                        <Grid item lg={3} xs={12}>
                            <YoutubeEmbed embedId="4rvw2FK_dc4" />
                        </Grid>

                        <Grid item lg={3} xs={12}>
                            <YoutubeEmbed embedId="VWbLa7fNt_A" />
                        </Grid>

                        <Grid item lg={3} xs={12}>
                            <YoutubeEmbed embedId="Wfe5fkovB0U" />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>


        <section className="section_2">
            <Container maxWidth="lg">
                <h2>
                    Galeri
                </h2>

                <hr color="#1CCAD8" style={{ width: "10%" }} />

                <p className="text-secondary">
                Çalışma ortamımızdan ve kullandığımız makinelerden bir kaç kesit.
                </p>
                
                <GalleryCarousel viewItems={galleryItems} />
            </Container>
        </section>

        <section className="section_1">
            <Container maxWidth="lg">
                <h2>
                    Müşterilerimizden Yorumlar
                </h2>

                <hr color="#1CCAD8" style={{ width: "10%" }} />

                <p className="text-secondary">
                Müşterilerimizin bizim hakkında düşündükleri.
                </p>

                <div id="comments">
                    
                    <Carousel
                    navButtonsAlwaysVisible
                    autoPlay
                    cycleNavigation
                    animation="slide"
                    duration={450}
                    indicators={false}
                    >
                        {UserComments.map((item, index) => {
                            return(
                                <div className="comment_item" key={"comment_id_" + index}>
                                    <div className="avatar">
                                        <img src={item.image} alt="" />
                                    </div>

                                    <h4>
                                       {item.name}
                                       <Divider orientation="vertical" style={{ marginLeft: 8, marginRight: 8 }} />
                                       <span>
                                        {item.mission}
                                       </span>
                                    </h4>

                                    <h2 className="title">
                                        {item.title}
                                    </h2>

                                    <p>
                                        {item.description}
                                    </p>
                                </div>
                            )   
                        })}
                    </Carousel>
                </div>
            </Container>
        </section>
        </>
    )
}

export default Home;