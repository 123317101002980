import { Grid } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { generateUUID } from "../Utils/utils";
import Carousel from 'react-material-ui-carousel'

interface GalleryCarouselProps{
    viewItems?: string[],
    variant?: "default" | "slider"
}

function GalleryCarousel(props:GalleryCarouselProps) {
    const { viewItems } = props;
    const [items, setItems] = useState<string[]>([])
    const [selectedIndex, setSelectedIndex] = useState(0)

    useEffect(() => {
        
        if(viewItems == undefined) return;
        setItems(viewItems)
        setSelectedIndex(0);
    }, [viewItems]);

    
    

    if(props.variant == "default"){
        return(
            <Grid container direction="row" justifyContent={"center"} spacing={2}>
                <Grid item xl={12}>
                    <div style={{ 
                        objectFit: "contain",
                        objectPosition: "center",
                        position: "relative", display: "flex", alignItems: "center", height: 400 }}>
    
                        <img style={{ 
                            objectFit: "contain",
                            objectPosition: "center",
                            width: "100%", height: "100%" }} src={items[selectedIndex]} alt="" />
    
                    </div>
                </Grid>
    
                <Grid item xl={12} container justifyContent={"center"} spacing={2}>
                    {items.map((item, index) => {
                        return(
                            <Grid item key={"a_gallery_corousel_item_" + generateUUID()}>
                                <div onClick={() => setSelectedIndex(index)} className={clsx("aza_glr_item", selectedIndex == index && "aza_glr_item_active")} key={"select_glr_cr_" + index}>
                                    <img style={{ 
                                        objectFit: "cover",
                                        objectPosition: "center",
                                        width: "100%", height: "100%" }} src={item} alt="" />
                                </div>
                            </Grid>
                        )
                    })}             
                </Grid>
            </Grid>
        )
    }else{
        return(
            <>
            <Carousel
            navButtonsAlwaysVisible
            autoPlay
            cycleNavigation
            animation="slide"
            duration={450}
            
            >
                {viewItems!.map((item, index) => {
                    return(
                        <>
                        <div style={{ 
                            
                            position: "relative", display: "flex", alignItems: "center", height: 700 }}>
    
                            <img style={{ 
                                objectFit: "cover",
                                objectPosition: "center",
                                width: "100%", height: "100%", maxHeight: "100%" }} src={item} alt="" />
    
                        </div>
                        </>
                    )
                })}
            </Carousel>
            </>
        )
    }

    /**/
}

export default GalleryCarousel