

export const employess = [
    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Dinçer BAYDAR",
        mission: "Yönetim Kurulu Başkanı",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Sefa ELİBOL",
        mission: "Yönetici",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Serkan MENGİ",
        mission: "Müşteri Temsilcisi",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Mustafa SEYİT",
        mission: "Muhasebe Sorumlusu",
        description: "",
        mail: ""
    },
    
    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Yasin ÖZDEMİR",
        mission: "Muhasebe Sorumlusu",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Cihan DEMİRDAĞ",
        mission: "Ofisboy",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Samet TOPAL",
        mission: "Müşteri Temsilcisi",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Hasan YILMAZ",
        mission: "Pazarlama Sorumlusu",
        description: "",
        mail: ""
    },

    
    
    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Mesut ATEŞ",
        mission: "Makine Ustası",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Ahmet Eren DAYLAK",
        mission: "Kalfa",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Mahmut Can KEÇELİ",
        mission: "Sevkiyat",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Ömer BELDİK",
        mission: "Kalfa",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Yunus Emre DAYLAK",
        mission: "Paketleme Sorumlusu",
        description: "",
        mail: ""
    },

    {
        avatarUrl: "https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg",
        name: "Hüseyin Beldik",
        mission: "Muhasebe Sorumlusu",
        description: "",
        mail: ""
    }
   
]