import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GalleryCarousel from "../Components/GalleryCarousel";
import ProductShortcut from "../Components/ProductShortcut";
import { GetProductDetails, GetSuggestProducts } from "../Utils/Store/functions";
import { IProduct } from "../Utils/Store/reducers/panelReducer";


function ProductDetails() {


    const { portfoyid } = useParams<{
        portfoyid: string
    }>();
    const [product, setProduct] = useState<IProduct>();
    const [suggestProducts, setSuggestProducts] = useState<IProduct[]>([]);

    useEffect(() => {
        setProduct(GetProductDetails(portfoyid))
        setSuggestProducts(GetSuggestProducts(4))
        console.log(product?.productImages);
        
    }, [portfoyid]);

    return(
        <section id="product_details">
            <Container maxWidth="xl">
                <Grid container direction="row" spacing={2}>
                    <Grid item container spacing={2}>
                        <Grid item xl={4}>
                            <GalleryCarousel variant="default" viewItems={product?.productImages} />
                        </Grid>
                        <Grid item xl={8}>
                            <div style={{ marginLeft: 16, height: "100%" }}>
                                <h2>
                                    {product?.productName}
                                </h2>

                                <p className="text-secondary">
                                {product?.productDetailText}
                                </p>

                                <hr />

                                <p>
                                {product?.productDescription}
                                </p>
                            </div>
                        </Grid>
                    </Grid>

                    

                    <Grid item xl={12} container justifyContent={"center"} flexDirection="column" style={{ marginTop: 36, textAlign: "center" }}>
                        <h3>Bazı Diğer Ürünlerimiz</h3>
                        <hr color="#1CCAD8" style={{ width: "25%" }} />
                    </Grid>

                    <Grid container spacing={4} item xl={12}>
                        {suggestProducts.map((product, index) => {
                            return(
                                <Grid item xl={3} xs={12} key={"product_detail_suggest_product_" + index}>
                                    <ProductShortcut productItem={product} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

export default ProductDetails;